import TomSelect from 'tom-select/dist/js/tom-select.complete.min.js';
let fieldsAreInitialized = false;

function changeFileUpload() {
  const multiFileUploads = document.querySelectorAll(
    '.gform_fileupload_multifile'
  );
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach(function (fileUpload) {
      fileUpload.querySelector('.gform_drop_instructions').textContent =
        wp.i18n._x(
          'Drag and drop file to upload',
          'drag and drop instruction',
          'vtx'
        );
    });
  }
}

// -------------------- File upload
function customFileUpload() {
  const fileInputs = document.querySelectorAll('input[type="file"]');

  let count = 0;
  fileInputs.forEach((fileInput) => {
    count++;

    // Create the div element and insert it before the file input
    const div = document.createElement('div');
    div.id = `file--${count}`;
    div.className = 'attachment';

    // Insert the div before the file input
    fileInput.parentNode.insertBefore(div, fileInput);

    fileInput.classList.add(`fileInput${count}`);

    // Create a wrapper div with a width and height at 0 and wrap the upload button with it
    const wrapper = document.createElement('div');
    wrapper.style.height = '0';
    wrapper.style.width = '0';
    wrapper.style.overflow = 'hidden';

    // wrap fileInput with wrapper
    fileInput.parentNode.insertBefore(wrapper, fileInput);
    wrapper.appendChild(fileInput);

    // Add event listener for file input change
    fileInput.addEventListener('change', function () {
      const grandParent = fileInput.parentNode.parentNode;

      const attachmentFileDiv = grandParent.querySelector('.attachment');

      attachmentFileDiv.innerHTML = this.value;
    });

    // Add event listener for div click
    div.addEventListener('click', function () {
      fileInput.click();
    });

    // Show the div
    div.style.display = 'block';
  });
}
// -------------------- END File upload

// -------------------- Form Labels
function changeFormLabel(label, input) {
  if (input) {
    if ('' !== input.value) {
      label.classList.add('active');
    }

    label.addEventListener('click', function () {
      input.focus();
    });

    input.addEventListener('focus', function () {
      label.classList.add('active');
    });

    input.addEventListener('focusout', function () {
      if (!input.value || '(___) ___-____' === input.value) {
        label.classList.remove('active');
      }
    });
  }
}

function addFormEvents() {
  const fields = document.querySelectorAll('.gfield');

  if (0 < fields.length) {
    fields.forEach(function (field) {
      if (field.classList.contains('initialized')) {
        return;
      }

      const classesExcluded = [
        'gfield--radio',
        'gfield--checkbox',
        'gfield--consent',
        'gfield--select',
      ];

      for (let i = 0; i < classesExcluded.length; i++) {
        if (field.classList.contains(classesExcluded[i])) {
          return;
        }
      }

      field.classList.add('initialized');

      const label = field.querySelector('.gfield_label');
      const input = field.querySelector(
        '.ginput_container input , .ginput_container textarea'
      );

      if (label && input) {
        changeFormLabel(label, input);
      }

      if (field.classList.contains('gfield--has-multiple-inputs')) {
        const subfields = field.querySelectorAll('.ginput_complex > span');

        if (0 < subfields.length) {
          subfields.forEach(function (subfield) {
            if (subfield.classList.contains('initialized')) {
              return;
            }
            subfield.classList.add('initialized');

            const sublabel = subfield.querySelector('label');
            const subinput = subfield.querySelector('input, textarea');
            changeFormLabel(sublabel, subinput);
          });
        }
      }
    });
  }
}

document.addEventListener('gform_post_render', function () {
  fieldsAreInitialized = true;
  changeFileUpload();
  addFormEvents();
});

function customDropdownSelect() {
  const formDropdownFields = document.querySelectorAll('.gfield_select');

  if (0 < formDropdownFields.length) {
    formDropdownFields.forEach(function (dropdownField) {
      new TomSelect(dropdownField);
    });
  }
}

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    changeFileUpload();
    customDropdownSelect();
    addFormEvents();
    customFileUpload();
  }
});
