import domReady from '@wordpress/dom-ready';

domReady(() => {
  const navSecondaryToggle = document.querySelector('.navSecondaryToggle');
  const navSecondaryBurger = document.querySelector('.navSecondaryBurger');
  const navSecondaryWrapper = document.querySelector('.navSecondaryWrapper');

  navSecondaryToggle.addEventListener('click', () => {
    const ariaExpanded = navSecondaryBurger.getAttribute('aria-expanded');
    navSecondaryToggle.setAttribute(
      'aria-expanded',
      ariaExpanded === 'true' ? 'false' : 'true'
    );
    navSecondaryBurger.setAttribute(
      'aria-expanded',
      ariaExpanded === 'true' ? 'false' : 'true'
    );
    navSecondaryWrapper.setAttribute(
      'aria-expanded',
      ariaExpanded === 'true' ? 'false' : 'true'
    );
  });
  // Close the menu when clicking outside the secondary nav
  document.addEventListener('click', (event) => {
    if (
      !navSecondaryWrapper.contains(event.target) &&
      !navSecondaryToggle.contains(event.target)
    ) {
      navSecondaryToggle.setAttribute('aria-expanded', 'false');
      navSecondaryBurger.setAttribute('aria-expanded', 'false');
      navSecondaryWrapper.setAttribute('aria-expanded', 'false');
    }
  });

  // make .navbar--top disappear when scrolling down and reappear when scrolling up
  let lastScrollTop = 0;
  const siteHeader = document.querySelector('.site-header');
  const navbar = document.querySelector('.navbar');
  const navbarTop = document.querySelector('.navbar--top');
  const navbarMain = document.querySelector('.navbar--main');
  window.addEventListener('scroll', () => {
    const currentScroll =
      window.pageYOffset || document.documentElement.scrollTop;
    if (currentScroll > lastScrollTop) {
      siteHeader.classList.add('navbar--hidden');
      navbar.classList.add('navbar--hidden');
      navbarTop.classList.add('navbar--hidden');
      navbarMain.classList.add('navbar--hidden');
    } else {
      siteHeader.classList.remove('navbar--hidden');
      navbar.classList.remove('navbar--hidden');
      navbarTop.classList.remove('navbar--hidden');
      navbarMain.classList.remove('navbar--hidden');
    }
    lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
  });
});
